import { useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import Tabs from '../../../components/v2/Tabs';
import { Tab } from '@mui/material';
import React from 'react';

export const NavigationTabs = ({ defaultTab, onTabChange, tabs }) => {
  const params = useParams();

  const selectedTab = params.tab ?? defaultTab;
  return (
    <TabContext value={selectedTab}>
      <Tabs value={selectedTab} onChange={onTabChange} indicatorColor="secondary" variant="scrollable" scrollButtons="auto">
        {tabs.map(tab => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      {tabs.map(tab => (
        <TabPanel key={tab.value} value={tab.value}>
          {<tab.Component aria-label={`tab ${tab.value}`} />}
        </TabPanel>
      ))}
    </TabContext>
  );
};
