import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import Agency from 'models/Agency';

export const mediaContentKey = mediaContentId => ['mediaContent', mediaContentId];

export const useMediaContentQuery = ({ mediaContentId }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: mediaContentKey(mediaContentId),
    queryFn: async () => {
      const response = await Api.getAgency(mediaContentId);
      return new Agency(response?.data);
    },
    retry: (_failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return true;
    },
    cacheTime: 0,
  });
  return query;
};

export const useMediaContentForAccommodation = supplierCode => {
  const Api = useApi();
  const query = useQuery({
    queryKey: `${supplierCode}-carousel`,
    queryFn: async () => {
      const response = await Api.getMediaContentCarouselByCamp(supplierCode);
      return response.data;
    },
  });
  return query;
};

export const useUpdateMediaContent = () => {
  const Api = useApi();
  //const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async mediaContents => {
      const promises = mediaContents.map(mediaContent => {
        const { id } = mediaContent;
        return Api.patchMediaContent(id, mediaContent);
      });
      const res = await Promise.all(promises);
      const data = res.map(result => result.data);
      const errors = res.filter(result => !!result?.error).map(result => result.error);
      if (errors.length > 0) {
        return { error: errors };
      }
      return { data };
    },
  });
  return mutation;
};
/*

const mutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      const response = await Api.upsertCampInfoEdits(id, payload);
      await queryClient.invalidateQueries({ queryKey: ['camp-info-list'] });
      return response?.data || [];
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
  return mutation;

* */
