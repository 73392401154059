import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { get } from 'lodash';

export const agencyDetailsKey = agencyId => ['agency', agencyId];
export const useAgencyAgents = agencyId => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['agency', agencyId, 'agents'],
    queryFn: async () => {
      const response = await Api.getAgencyAgents(agencyId);
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
  return query;
};

export const useMyAgencySettingsQuery = ({ agencyId }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: [agencyId, 'myAgencySettings'],
    queryFn: async ({ _queryKey }) => {
      const response = await Api.getMyAgencySettings(agencyId, {
        include: ['lightlogos', 'darklogos', 'featuredvideos', 'featuredphotos', 'featuredvideopreviews'],
      });
      const data = response?.data;
      const types = [
        ['featuredvideos', 'featuredVideo'],
        ['featuredvideopreviews', 'featuredVideoPreview'],
        ['featuredphotos', 'featuredPhoto'],
        ['darklogos', 'logoDark'],
        ['lightlogos', 'logoLight'],
      ];
      for (const type of types) {
        const [collection, target] = type;
        if (get(data, collection, []).length) {
          data[target] = data[collection][0];
        } else {
          data[target] = '';
        }
        delete data[collection];
      }
      return data;
    },
    retry: (_failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return true;
    },
  });
  return query;
};
