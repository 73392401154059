import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { keyBy } from 'lodash';
import qs from 'qs';

export const useCampsInfoMapQuery = ({ instance, enabled }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['camp-info-map', instance?.id],
    queryFn: async () => {
      const accommodations = instance.accommodationsFromSegments;
      const response = await Api.getCamp({
        where: {
          supplierCode: {
            in: (instance?.itinerary?.params?.accommodations || accommodations).map(accommodation => accommodation.supplierCode),
          },
        },
        fields: ['supplierCode', 'campName', 'regionName', 'campInfo', 'country', 'featuredImageId', 'dmcArrangedOnly'],
        include: {
          relation: 'featuredImage',
          scope: {
            fields: ['s3Key'],
          },
        },
      });
      return keyBy(response?.data || [], 'supplierCode');
    },
    staleTime: Number.Infinity,
    enabled,
  });
  return query;
};
//TODO what is enabled?
export const useCampInfoQuery = ({ id, enabled = true }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['camp-info', id],
    queryFn: async () => {
      const response = await Api.getCampInfo(id);
      return response?.data || [];
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled,
  });
  return query;
};

export const useCampInfoEditsQuery = ({ id, enabled = true }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['camp-info-edits', id],
    queryFn: async () => {
      const response = await Api.getCampInfoEdits(id);
      return response?.data || [];
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled,
  });
  return query;
};
//TODO - find out how to invalidate state
export const useCampInfoEditsUpsert = props => {
  const Api = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      const response = await Api.upsertCampInfoEdits(id, payload);
      await queryClient.invalidateQueries({ queryKey: ['camp-info-list'] });
      return response?.data || [];
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
  return mutation;
};
export const useCampInfoEditsPatch = props => {
  const Api = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      await Api.patchCampInfoEdits(id, payload);
      await queryClient.invalidateQueries({ queryKey: ['camp-info-list'] });
      return true;
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
  return mutation;
};

export const useCampsListQuery = ({ filters = {}, enabled = true }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['camp-info-list', ...Object.values(filters)],
    queryFn: async () => {
      const response = await Api.getCampsList(qs.stringify(filters));
      return response?.data ?? [];
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled,
  });
  return query;
};
