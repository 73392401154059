import { isAdmin, isSuperContentManager, isSuperAdmin, isContentManager } from '../utils/acls';
import { isAgentRoute, isAdminRoute, isContentManagerRoute, isSuperAdminRoute, isSuperContentManagerRoute } from '../utils/routes';

export const blocks = {
  agent: {
    test: () => true,
    filter: isAgentRoute,
  },
  admin: {
    test: isAdmin,
    label: 'Lead Agents',
    filter: isAdminRoute,
  },
  contentManager: {
    test: isContentManager,
    label: 'Content Managers',
    filter: isContentManagerRoute,
  },
  superContentManager: {
    test: isSuperContentManager,
    label: 'Content Managers - Super Admins',
    filter: isSuperContentManagerRoute,
  },
  superAdmin: {
    test: isSuperAdmin,
    label: 'Super Admins',
    filter: isSuperAdminRoute,
  },
};
