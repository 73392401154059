import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, IconButton, Typography } from '@mui/material';
import { Star as StarIcon, StarBorder as StarBorderIcon } from '@mui/icons-material';
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFieldArray, useForm, /*Controller, */ useFormState, useWatch } from 'react-hook-form';

import { CampFeaturesEditor, ColorSelector } from '../../components';
import { useCampDetails } from '../../hooks';

import Tooltip from 'components/v2/Tooltip';
import InputRhf from 'components/v2/FormElements/InputRhf';
// TODO - should me moved into agent/components/v2
import FormButtons from '../../../../Itinerary/ItineraryDetail/Presentation/Pdf/FormButtons';

const getDefaultValue = camp => ({
  _id: camp.supplierCode,
  campName: camp.campName ?? '',
  campInfo: camp.campInfo ?? '',
  latitude: camp.latitude ?? 0,
  longitude: camp.longitude ?? 0,
  internalNote: camp.internalNote ?? '',
  internalNoteColor: camp.internalNoteColor ?? '#123438',
  campFeatures: camp.campFeatures ?? [],
  preferred: camp.preferred ?? false,
  flag: camp.flag ?? '',
  flagDescription: camp.flagDescription ?? '',
});
const campSchema = yup.object({
  campName: yup
    .string()
    .required('Please enter an camp name')
    .test('no-leading-trailing-spaces', 'No leading or trailing whitespace', value => value === value.trim()),
  campInfo: yup.string(),
  latitude: yup
    .number()
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .min(-180)
    .max(180),
  internalNote: yup.string(),
  internalNoteColor: yup.string(),
  campFeatures: yup.array().of(yup.string()),
  preferred: yup.bool(),
  flag: yup.string().test('len', 'A flag can only have a maximum length of 30', val => val.length <= 30),
  flagDescription: yup.string(),
});

export const Details = () => {
  const params = useParams();
  const supplierCode = params.supplierCode;

  const { activities, amenities, campInfo, save, reset: resetRemote } = useCampDetails(supplierCode);

  //Begin Form - bring zustand state into react forms state for transient edits
  const { handleSubmit, control, setValue, reset, getValues } = useForm({
    resolver: yupResolver(campSchema),
    mode: 'onBlur',
    defaultValues: getDefaultValue(campInfo),
  });

  // when data is refetched reset the form to remote source of truth, maybe move into the hook.... which is slowly becoming a context
  useEffect(() => {
    reset(getDefaultValue(campInfo));
  }, [campInfo, reset]);

  const { dirtyFields, isDirty } = useFormState({ control });
  // it should be noted, using the below methods cause the whole dirtyFields model to be returned with true/false values
  const {
    //fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'campFeatures',
  });

  const handleActivitiesAmenities = useCallback(
    (id, checked) => {
      const { campFeatures } = getValues();
      if (checked) {
        append(id);
      } else {
        remove(campFeatures.indexOf(id));
      }
    },
    [append, remove, getValues],
  );
  //TODO - move into the hook with query and store, but dirty fields
  const onSubmit = useCallback(
    async data => {
      // starting to think this could be moved into the submit function keeping
      const formData = Object.keys(dirtyFields);

      const patch = {};
      // dirtyFields is just { filed1:true, field2: true } until you modify an array or nested fields
      // when modifying an array RHF returns { field1: true,, field2: true, field3: false, filed4: [ true, false, true ] }
      formData.forEach(key => {
        if (dirtyFields[key] && !Array.isArray(dirtyFields[key])) {
          patch[key] = data[key];
        }
      });
      // to keep the logic simple a cheap array check
      if (JSON.stringify(campInfo?.campFeatures) !== JSON.stringify(data.campFeatures)) {
        patch.campFeatures = data.campFeatures;
      }
      try {
        const existingEdits = campInfo?.agencyEdits ?? {};
        const existingDetailsEdits = existingEdits.detailsPatch;
        const detailsPatch = { ...existingDetailsEdits, ...patch };
        await save(data._id, { campId: data._id, detailsPatch });
        //TODO - reload camps list
        reset(data);
      } catch {
        // error handler is handled in tanstack
      }
    },
    [campInfo?.campFeatures, campInfo?.agencyEdits, dirtyFields, reset, save],
  );

  const handleCancel = useCallback(async () => {
    await reset();
  }, [reset]);

  const handleReset = useCallback(async () => {
    await resetRemote(campInfo?._id, { detailsPatch: null });
  }, [resetRemote, campInfo?._id]);

  const onPreferredClicked = useCallback(() => {
    setValue('preferred', !getValues().preferred, { shouldDirty: true });
  }, [setValue, getValues]);
  // End Form

  useWatch({ control, name: 'preferred' });
  useWatch({ control, name: 'latitude' });
  useWatch({ control, name: 'longitude' });
  useWatch({ control, name: 'campFeatures' });
  useWatch({ control, name: 'flag' });

  const { preferred, latitude, longitude, campFeatures, flag } = getValues();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" mb={4}>
          Accommodation Details
        </Typography>
        <FormButtons {...{ isDirty, hasNoEdits: !campInfo?.agencyEdits?.detailsPatch, handleReset, handleCancel }} />
      </div>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={6}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
            <div>
              <InputRhf
                label="Camp Name"
                placeholder=" "
                control={control}
                name="campName"
                fullWidth
                endAdornment={
                  <Tooltip title="Preferred Accommodation">
                    <IconButton color="secondary" onClick={onPreferredClicked}>
                      {preferred ? <StarIcon /> : <StarBorderIcon color="disabled" />}
                    </IconButton>
                  </Tooltip>
                }
              />
            </div>
            <div>
              <InputRhf label="Latitude" placeholder=" " control={control} name="latitude" type="number" fullWidth />
            </div>
            <div>
              <InputRhf label="Longitude" placeholder=" " control={control} name="longitude" type="number" fullWidth />
            </div>
            <div style={{ paddingBottom: 25 }}>
              <Map
                key={`${latitude}-${longitude}`}
                style={{ width: '100%', height: '290px' }}
                defaultCenter={{ lat: latitude ? Number.parseFloat(latitude) : 0, lng: longitude ? Number.parseFloat(longitude) : 0 }}
                defaultZoom={10}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                mapId="accommodation-map"
              >
                <AdvancedMarker
                  position={{ lat: latitude ? Number.parseFloat(latitude) : 0, lng: longitude ? Number.parseFloat(longitude) : 0 }}
                >
                  <Pin background={'#FF6E00'} borderColor={'#123438'} glyphColor={'#123438'} />
                </AdvancedMarker>
              </Map>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputRhf label="Description" placeholder=" " control={control} name="campInfo" fullWidth multiline rows={22} />
        </Grid>
      </Grid>
      <Typography variant="body1" mb={4}>
        Internal Accommodation Details (Shows up on the Itinerary Builder)
      </Typography>
      <Grid container mb={4}>
        <Grid item xs={12} md={4} mb={4}>
          <div>
            <Typography variant="body2" mb={2} sx={{ fontWeight: 500 }}>
              Internal Note Color
            </Typography>
            <ColorSelector control={control} name="internalNoteColor" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <InputRhf label="Internal Note" placeholder=" " control={control} name="internalNote" fullWidth multiline rows={5} />
        </Grid>
      </Grid>
      <Typography variant="body1" mb={4}>
        Accommodation Extras
      </Typography>
      <Grid container mb={4}>
        <Grid item xs={12} md={5} mb={4}>
          <InputRhf label="Accommodation Flag" placeholder=" " control={control} name="flag" fullWidth inputProps={{ maxLength: 30 }} />
          {/* negative margin hack because the form element saves room for an error */}
          <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: -15 }}>
            <Typography variant="body2" color="#9e9e9e">
              {flag.length}/30
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} mb={4}>
          <InputRhf
            label="Accommodation Flag Description"
            placeholder=" "
            control={control}
            name="flagDescription"
            fullWidth
            multiline
            rows={5}
          />
        </Grid>
      </Grid>
      <Grid container mb={4}>
        {[
          { title: 'Activity', options: activities },
          { title: 'Amenities', options: amenities },
        ].map((item, index) => (
          <CampFeaturesEditor
            key={index}
            title={item.title}
            options={item.options}
            selected={campFeatures}
            onToggle={handleActivitiesAmenities}
          />
        ))}
      </Grid>
    </form>
  );
};
