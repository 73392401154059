import { create } from 'zustand';
import { /*persist,*/ devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export const useCampsStore = create(
  devtools(
    immer(
      set => ({
        camps: [],
        count: 0,
        campInfo: undefined,
        activities: [],
        amenities: [],
        mediaContent: [],
        //TODO - for camps list, filters are in component state ATM
        page: 0,
        itemsPerPage: 10,
        setCamps: camps =>
          set(state => ({
            ...state,
            camps: camps.map(camp => ({ type: 'stay', ...camp })),
          })),
        setCount: count =>
          set(state => ({
            ...state,
            count,
          })),
        setCamp: campInfo => {
          return set(state => ({
            ...state,
            campInfo,
          }));
        },
        setActivitiesAndAmenities: features => {
          return set(state => ({
            ...state,
            activities: features?.filter(feature => feature.featureType.toLowerCase() === 'activity') ?? [],
            amenities: features?.filter(feature => feature.featureType.toLowerCase() === 'amenity') ?? [],
          }));
        },
        setMediaContent: mediaContent => {
          return set(state => ({
            ...state,
            mediaContent: mediaContent ?? [],
          }));
        },
      }),
      { name: 'CampStore' },
    ),
  ),
);
