import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import { useCampDetails } from '../../hooks';
import PhotoGrid from '../../../components/PhotoGrid';
import PhotoUploaderModal from '../../../../../components/v2/Modals/PhotoUploaderModal';
import { useUser } from 'common/hooks/user';

export const Content = () => {
  const params = useParams();
  const supplierCode = params.supplierCode;
  const { user } = useUser();
  const { campInfo, mediaContent, save } = useCampDetails(supplierCode);

  const [selected, setSelected] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const onChange = async photos => {
    /*
    TODO - this should be done in a migration
    TODO- find out why Unhandled Rejection (TypeError): Cannot assign to read only property 'data' of object '#<Object>'
    for(const photo of photos){
      if(photo.data) {
        photo.data = undefined;
      }
    }*/

    const mediaContentPatch = { ...campInfo.mediaContentPatch, photos };
    await save(campInfo.supplierCode, { campId: campInfo.supplierCode, mediaContentPatch });
  };

  const onDeleteOne = async photo => {
    const photos = (campInfo?.mediaContent?.photos ?? mediaContent).filter(p => p.id !== photo.id);
    await onChange(photos);
  };

  const onDeleteMany = async () => {
    const photos = (campInfo?.mediaContent?.photos ?? mediaContent).filter(p => !selected.includes(p.id));
    await onChange(photos);
    setSelected([]);
  };

  const toggleSelected = e => {
    setSelected(state => {
      if (e.target.checked) {
        state.push(e.target.value);
      } else {
        state = state.filter(sel => sel.id !== e.target.value);
      }
      return state;
    });
  };
  const onUploadSuccess = res => {
    res.data.map(img => ({
      ...img,
      id: img.id,
      // presentationVisibility: true,
    }));
    onChange([...res.data, ...(campInfo?.mediaContent?.photos ?? mediaContent)]);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" mb={4}>
          Accommodation Content
        </Typography>
        <div>
          <Button variant="outlined" sx={{ mr: 1 }} onClick={onDeleteMany}>
            Delete Selection
          </Button>
          <Button variant="outlined" onClick={() => setUploadModalVisible(true)}>
            Add Photos
          </Button>
        </div>
      </div>
      {/*TODO - verify region carousel do no come though in production*/}
      <PhotoGrid
        mediaContent={campInfo?.mediaContent?.photos ?? mediaContent}
        handleToggle={toggleSelected}
        handleDelete={onDeleteOne}
        selected={selected}
        handleChange={onChange}
      />
      <PhotoUploaderModal
        open={uploadModalVisible}
        handleClose={setUploadModalVisible}
        mediaMetadata={{
          agencyContent: true,
        }}
        orderOffset={mediaContent?.length}
        onUploadSuccess={onUploadSuccess}
        updateMutation={d => {
          return {
            id: d.id,
            collections: ['Agency Upload', 'Accommodation'],
            country: campInfo.countryCode,
            region: campInfo.countryRegionCode,
            processed: true,
            status: 'approved',
            accommodation: campInfo.supplierCode,
            agencyId: user?.agency?.id,
          };
        }}
      />
    </>
  );
};
